<template>
  <div class="wap-auth">
    <headerBar :title="$t('shou-ji-hao-ren-zheng')" @back="back"></headerBar>

    <div class="main wap-page-form">
      <div class="form-item">
        <div class="label">{{ $t('shou-ji-hao') }}</div>
        <div class="input">
          <Field
            v-model="form.phone"
            :placeholder="$t('qing-shu-ru-shou-ji-hao-14')"
          >
            <template #left-icon>
              <div class="flex-center" @click="changeCountry">
                {{ form.phonePre }}
                <i class="el-icon-caret-bottom"></i>
              </div>
            </template>
          </Field>
        </div>
      </div>
      <div class="form-item">
        <div class="label">{{ $t('yan-zheng-ma-0') }}</div>
        <div class="input">
          <Field
            v-model="form.realName"
            :placeholder="$t('qing-shu-ru-yan-zheng-ma-6')"
          >
            <template #button>
              <div class="blue link">{{ $t('fa-song-yan-zheng-ma') }}</div>
            </template>
          </Field>
        </div>
      </div>
      <div class="form-item">
        <div class="label">{{ $t('deng-lu-mi-ma') }}</div>
        <div class="input">
          <Field
            v-model="form.password"
            :type="showPassword ? 'text' : 'password'"
            :placeholder="$t('qing-shu-ru-deng-lu-mi-ma-4')"
          >
            <template #button>
              <div class="right-icon" @click="showPassword = !showPassword">
                <img :src="eyeUrl" alt="" v-if="!showPassword" />
                <img :src="eyeUrl2" alt="" v-else />
              </div>
            </template>
          </Field>
        </div>
      </div>

      <div class="form-btn">
        <Button class="submit-btn">{{ $t('ren-zheng') }}</Button>
      </div>
    </div>

    <CountryList
      :showDialog.sync="showDialog"
      :phonePre.sync="form.phonePre"
    ></CountryList>
  </div>
</template>
<script>
import { Button, DatetimePicker, Popup, Switch as vanSwitch, Field } from 'vant'
import headerBar from '@/components/header'
import CountryList from '@/components/countryList'
import moment from 'moment'
import { getToken } from '@/utils/auth'
export default {
  components: {
    CountryList,
    Button,
    headerBar,
    DatetimePicker,
    vanSwitch,
    Popup,
    Field
  },
  data() {
    return {
      avatar: '',
      form: {
        phone: '',
        phonePre: '+86'
      },
      headers: {},
      showDialog: false,
      showPassword: false,
      eyeUrl: require('@/assets/imgs/icon-eye-black.png'),
      eyeUrl2: require('@/assets/imgs/icon-eye2-black.png')
    }
  },
  computed: {
    langList() {
      return this.$store.state.langList
    },
    lang() {
      return this.$store.state.lang
    }
  },
  mounted() {
    this.headers = {
      Token: getToken()
    }
  },
  methods: {
    back() {
      this.$router.go(-1)
    },
    save() {
      this.$store.state.avatar = this.avatar
      this.back()
    },
    changeHeader(i) {
      this.avatar = i
    },
    changeCountry() {
      this.showDialog = true
    }
  }
}
</script>